
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");


const ENTITYID="nnseminar"


const seriespickerfields = [
	{"label":"Name", "dbfield": "nnseminarseries_name", "type": "text", "filtertype": "textbox"},
	{"label":"Summary", "dbfield": "nnseminarseries_summary", "type": "text", "filtertype": "textbox"}
];

const seriespickerfilter = [];


const searchFields = [
	{"label":"Start Time", "dbfield": "nnseminar_datetimestart", "type": "datetime", "filtertype": "datetime"},
	{"label":"Title", "dbfield": "nnseminar_name", "type": "text", "filtertype": "text"},
	{"label":"Banner", "dbfield": "nnseminar_banner", "type": "image", "filtertype": "image"},
	{"label":"Summary", "dbfield": "nnseminar_summary", "type": "text", "filtertype": "text"},
	{"label":"Registration Deadline", "dbfield": "nnseminar_deadline", "type": "date", "filtertype": "date"},
];

const formFields = [
	[
		{"label":"Title", "field": "nnseminar_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Has Page", "field": "nnseminar_haspage", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Webpage", "field": "nnseminar_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/seminar/"},
	]
];


const newFormFields = [
	[
		{"label":"Title", "field": "nnseminar_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Series Name", "field": "nnseminarseries_name", "value": "", "input": "picker", "mode": "required", "pickerfields": seriespickerfields, "pickerfilters": seriespickerfilter},
	]
];



const SeminarForm = ({location, allowadd, allowdelete, customnewformfields, customeditformfields, editSubFormFields, newSubformFields}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	//const [seminarurl, setSeminarurl] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;

		/*
		// Change file location to include seminar url
		const locationcheck = "file/seminar/urlplaceholder";
		var tmpsubformidx = 0;
		var tmprowidx = 0;
		var tmpcolidx = 0;
		while (tmpsubformidx < subformpayloadlist.length) {
			tmprowidx = 0;
			while (tmprowidx < subformpayloadlist[tmpsubformidx].length) {
				tmpcolidx = 0;
				while (tmpcolidx < subformpayloadlist[tmpsubformidx][tmprowidx].length) {
					if (locationcheck === subformpayloadlist[tmpsubformidx][tmprowidx][tmpcolidx].location) {
						subformpayloadlist[tmpsubformidx][tmprowidx][tmpcolidx].location = "file/seminar/"+seminarurl;
					}
					tmpcolidx++;
				}
				tmprowidx++;
			}
			tmpsubformidx++;
		}
		*/

		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		/*
		if (params.hasOwnProperty("nnseminar_datetimeend")) {
			params.nnseminar_deadline=params.nnseminar_datetimeend.substring(0,10);
		}
		*/
		if (pkid === 0) {
			params.nnseminarq_id=3;	// Basic Questionaire
			if (params.nnseminar_datetimestart >= params.nnseminar_datetimeend) {
				callback({"status":"Error", "message":"End Time earlier than Start Time"});
				return;
			}
			if (params.hasOwnProperty("nnseminarseries_id")) {
				webappsAPI.loadRecord("nnseminarseries", params.nnseminarseries_id, token).then(dataresponse => {
					if (dataresponse.hasOwnProperty("fields")) {
						var rowidx = 0;
						var colidx = 0;
						var tmpfield = "";
						while (rowidx < dataresponse.fields.length) {
							colidx = 0;
							while (colidx < dataresponse.fields[rowidx].length) {
								tmpfield = dataresponse.fields[rowidx][colidx].field;
								if (tmpfield === "onsbranch_id") {
									params.onsbranch_id = dataresponse.fields[rowidx][colidx].value;
								} else if (tmpfield === "nnseminarseries_name") {
									params.nnseminar_seriesname = dataresponse.fields[rowidx][colidx].value;
								} else if (tmpfield === "nnseminarseries_summary") {
									params.nnseminar_summary = dataresponse.fields[rowidx][colidx].value;
								} else if (tmpfield === "nnseminarseries_description") {
									params.nnseminar_description = dataresponse.fields[rowidx][colidx].value;
								}
								colidx++;
							} // while col
							rowidx++;
						} // while row
						webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
							if (response.status === "OK") {
								negosyonowAPI.activateEntity(response.documentid,token, "nnseminar").then(nnactivateresponse => {
									callback(response);
								});
							} else {
								callback(response);
							}
						});
						return;
					} // if fields
					callback({"status":"Error", "message":"Unable to load series data"});

				});
				return;
			} // needs to load series
		} // if new document
		// Fallthrough Existing document/No series information
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(response.documentid,token, "seminar").then(nnresponse => {
					callback(response);
				});
			} else {
				callback(response);
			}
		});

		//callback({"status":"Error"});
	}

	function customEditFieldInfo(inputlist, idx)
	{
		if (inputlist[idx].field !== "nnseminar_url") {
			return inputlist[idx];
		}
		const haspage = parseInt(inputlist[idx-1].value);
		if (haspage === 1) {
			return inputlist[idx];
		}
		var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.value = "Not yet available";
		tmpfield.input = "textbox";
		return tmpfield;
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var regfee = 0;
		var paymentdetails = "";
		var startdatevalue = "";
		var enddatevalue = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnseminar_datetimestart") {
				startdatevalue = mainform[idx].value;
			} else if (mainform[idx].field === "nnseminar_datetimeend") {
				enddatevalue = mainform[idx].value;
			} else if (mainform[idx].field === "nnseminar_regfee") {
				regfee = parseFloat(mainform[idx].value);
			} else if (mainform[idx].field === "nnseminar_paymentinstructions") {
				paymentdetails = mainform[idx].value;
		/*
			} else if (mainform[idx].field === "nnseminar_url") {
				setSeminarurl(mainform[idx].value);
		*/
			}
			idx++;
		}
		if (startdatevalue.length > 0 && enddatevalue.length > 0) {
			if (parseInt(enddatevalue.localeCompare(startdatevalue), 10) <= 0) {
				return {"status":"Error", "message":"End date should come after start date"};
			}
		}

		if (regfee > 0 && paymentdetails.length < 1) {
			return {"status":"Error", "message":"Please provide payment instructions"};
		}

		return {"status":"OK"};
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappsForm
				location={location}
				pagetitle={pagetitle}
				allowadd={allowadd}
				allowdelete={allowdelete}
				mobilerowtitlefield={["nnseminar_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(customeditformfields)]}
				newformFields={[newFormFields[0].concat(customnewformfields)]}
				editSubFormFields={editSubFormFields}
				newSubformFields={newSubformFields}
				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				userSearchParam={{"orderby":"nnseminar_datetimestart desc"}}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default SeminarForm;
